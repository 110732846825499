import { __decorate } from "tslib";
import { Vue, Component, Provide, Watch, Inject } from 'vue-property-decorator';
import Stepper from '@/components/shared/Stepper.vue';
import ObjectsPicker from '@/components/backups-comparison/ObjectsPicker.vue';
import BackupsComparisonApi from '@/services/api/backups-comparison.api';
import MergeObjects from '@/components/backups-comparison/MergeObjects.vue';
import AutomationProcesses from '@/components/backups-comparison/AutomationProcesses.vue';
import { isEmptyObject } from '@/utils';
let BackupsComparisonManagePage = class BackupsComparisonManagePage extends Vue {
    constructor() {
        super(...arguments);
        this.comparisonId = +this.$route.params.comparisonId;
        this.projectName = this.$route.query.projectName;
        this.storageId = +this.$route.query.storageId;
        this.objects = [];
        this.objectsSearch = '';
        this.selectedObjects = [];
        this.indexFieldsMap = new Map();
        this.objectsMergeResult = {};
        this.disableAutomationResult = {};
        this.loading = false;
        this.loadingMessage = '';
        this.steps = [
            { title: 'Select objects', name: 'objects', active: true, done: false },
            { title: 'Merge', name: 'merge', active: false, done: false },
            { title: 'Disable automation process', name: 'automation', active: false, done: false },
        ];
    }
    get objectsList() {
        const regexp = new RegExp(this.objectsSearch, 'i');
        return this.objects.filter(item => regexp.test(item.name));
    }
    get disabledObjects() {
        return this.objects.filter(item => !item.numberOfRecords || !item.isAccessible);
    }
    get objectsToMerge() {
        return this.selectedObjects.map(object => object.name);
    }
    get activeStep() {
        return this.steps.find(step => step.active)?.name || '';
    }
    async next() {
        try {
            if (this.activeStep === 'objects') {
                if (!this.selectedObjects.length) {
                    this.$toast.error('Select at least one object');
                    return;
                }
            }
            else if (this.activeStep === 'merge') {
                if (isEmptyObject(this.objectsMergeResult)) {
                    this.$toast.error('Select at least one record to merge');
                    return;
                }
                await this.mergeObjects();
            }
            else if (this.activeStep === 'automation') {
                await this.restore();
            }
            this.nextStep();
        }
        catch {
            this.$toast.error('Could not continue merge process due error');
        }
    }
    nextStep() {
        const activeStep = this.steps.find(step => step.active);
        const nextStep = this.steps[this.steps.indexOf(activeStep) + 1];
        if (activeStep) {
            activeStep.active = false;
            activeStep.done = true;
        }
        if (nextStep) {
            nextStep.active = true;
        }
    }
    onStepChanged(step) {
        if (step === 'objects') {
            this.fetchObjects();
        }
    }
    async fetchObjects() {
        try {
            this.loading = true;
            this.objects = await BackupsComparisonApi.fetchObjects(this.comparisonId, {
                organizationId: this.organizationId,
            });
            for (const object of this.objects) {
                this.indexFieldsMap.set(object.name, object.indexField || 'Id');
            }
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    async mergeObjects() {
        try {
            this.loading = true;
            for (const objectName in this.objectsMergeResult) {
                if (objectName in this.objectsMergeResult) {
                    this.loadingMessage = `Prepare "${objectName}" to merge`;
                    const mergeBody = {
                        objectName,
                        projectName: this.projectName,
                        mergeResult: this.objectsMergeResult[objectName],
                        organizationId: this.organizationId,
                    };
                    // eslint-disable-next-line no-await-in-loop
                    await BackupsComparisonApi.merge(this.comparisonId, mergeBody);
                }
            }
        }
        catch (error) {
            this.$toast.error(error.message);
            throw error;
        }
        finally {
            this.loadingMessage = '';
            this.loading = false;
        }
    }
    async restore() {
        try {
            this.loading = true;
            const restoreBody = {
                ...this.disableAutomationResult,
                projectName: this.projectName,
                organizationId: this.organizationId,
            };
            await BackupsComparisonApi.restore(restoreBody);
            this.$toast.success('Restore job successfully queued.');
            this.$router.replace({
                name: 'backups-comparison.index',
            });
        }
        catch (error) {
            this.$toast.error(error.message);
            throw error;
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], BackupsComparisonManagePage.prototype, "organizationId", void 0);
__decorate([
    Provide()
], BackupsComparisonManagePage.prototype, "comparisonId", void 0);
__decorate([
    Provide()
], BackupsComparisonManagePage.prototype, "projectName", void 0);
__decorate([
    Provide()
], BackupsComparisonManagePage.prototype, "storageId", void 0);
__decorate([
    Watch('activeStep', { immediate: true })
], BackupsComparisonManagePage.prototype, "onStepChanged", null);
BackupsComparisonManagePage = __decorate([
    Component({
        components: {
            AutomationProcesses,
            MergeObjects,
            Stepper,
            ObjectsPicker,
        },
    })
], BackupsComparisonManagePage);
export default BackupsComparisonManagePage;
