import { __decorate } from "tslib";
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { MergeSource } from '@flosum-backup/contracts';
import Expandable from '@/components/shared/Expandable.vue';
import BackupsComparisonApi from '@/services/api/backups-comparison.api';
let MergeObjects = class MergeObjects extends Vue {
    constructor() {
        super(...arguments);
        this.fileName = '';
        this.objectMergeResult = {};
        this.onlyDiffsList = {};
        this.openedObject = '';
        this.openedRecord = null;
        this.loading = false;
        this.records = [];
        this.describe = {};
        this.pagination = {
            page: 1,
            total: 0,
            limit: 50,
        };
    }
    get objectDescribe() {
        return this.describe[this.openedObject] || {};
    }
    isNotCreatebleField(field) {
        return !this.objectDescribe.fields[field]?.isCreateable;
    }
    isRequrieField(field) {
        return this.objectDescribe.fields[field]?.isRequired;
    }
    get objectDescribeFields() {
        if (!this.openedObject) {
            return [];
        }
        const fieldOfRecords = this.records.map(record => record.fields);
        const arrayFieldsOfRecord = fieldOfRecords.map(record => Object.keys(record));
        const uniqueNameFields = [...new Set(arrayFieldsOfRecord.flat(1))];
        const mergedFields = [...new Set([...uniqueNameFields, ...Object.keys(this.objectDescribe.fields)])].filter(field => field !== 'Id');
        return mergedFields;
    }
    get mergeResult() {
        return this.objectMergeResult[this.openedObject] ? this.objectMergeResult[this.openedObject][this.fileName] : {};
    }
    mounted() {
        this.fetchDescribe();
    }
    openRecord(record) {
        this.openedRecord = this.openedRecord === record ? null : record;
    }
    async open(object) {
        if (object === this.openedObject) {
            this.openedObject = '';
            return;
        }
        this.pagination.total = 0;
        this.pagination.page = 1;
        this.openedObject = object;
        await this.fetchRecords();
    }
    toggleRecordMergeResult(record, value) {
        if (value) {
            this.mergeResult[record.key] = {};
            this.toggleRecordMergeResultField(record.key, this.indexFieldsMap.get(this.openedObject) || 'Id', true, record.sourceIndexValue ? MergeSource.SourceBackup : MergeSource.TargetBackup);
        }
        else {
            delete this.mergeResult[record.key];
        }
        this.updateModel();
    }
    toggleRecordMergeResultField(key, field, value, source) {
        if (value) {
            this.mergeResult[key][field] = source;
        }
        else {
            delete this.mergeResult[key][field];
        }
        this.updateModel();
    }
    onPaginationChanged() {
        this.$nextTick(() => this.fetchRecords());
    }
    updateModel() {
        this.objectMergeResult = {
            ...this.objectMergeResult,
        };
        this.$emit('change', this.objectMergeResult);
    }
    async fetchDescribe() {
        try {
            this.loading = true;
            this.describe = await BackupsComparisonApi.fetchDescribe({
                projectName: this.projectName,
                objects: this.objects,
                organizationId: this.organizationId,
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    async fetchRecords() {
        try {
            this.records = [];
            this.loading = true;
            const { records, total, fileName } = await BackupsComparisonApi.fetchRecords(this.comparisonId, {
                objectName: this.openedObject,
                page: this.pagination.page,
                organizationId: this.organizationId,
            });
            this.records = records.map(record => ({
                ...record,
                key: `${record.sourceIndexValue}#${record.targetIndexValue}`,
            }));
            this.onlyDiffsList = this.records.reduce((acc, record) => ({
                ...acc,
                [record.key]: true,
            }), {});
            this.fileName = fileName;
            this.pagination.total = total;
            this.objectMergeResult[this.openedObject] ||= {};
            this.objectMergeResult[this.openedObject][this.fileName] ||= {};
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop({
        required: true,
    })
], MergeObjects.prototype, "objects", void 0);
__decorate([
    Prop({
        required: true,
    })
], MergeObjects.prototype, "projectName", void 0);
__decorate([
    Prop({
        required: true,
    })
], MergeObjects.prototype, "organizationId", void 0);
__decorate([
    Prop({
        required: true,
    })
], MergeObjects.prototype, "comparisonId", void 0);
__decorate([
    Prop({
        required: true,
    })
], MergeObjects.prototype, "indexFieldsMap", void 0);
__decorate([
    Model('change')
], MergeObjects.prototype, "model", void 0);
__decorate([
    Watch('pagination.page', { deep: true })
], MergeObjects.prototype, "onPaginationChanged", null);
MergeObjects = __decorate([
    Component({
        components: {
            Expandable,
        },
    })
], MergeObjects);
export default MergeObjects;
