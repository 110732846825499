import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
import ObjectsPicker from '@/components/backups-comparison/ObjectsPicker.vue';
let AutomationProcesses = class AutomationProcesses extends Vue {
    constructor() {
        super(...arguments);
        this.selectAll = false;
        this.selectedObjects = [];
        this.automationProcess = {
            disableProcessBuilder: false,
            disableTrigger: false,
            disableValidationRule: false,
            disableWorkflow: false,
        };
    }
    selectAllObjects() {
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
            this.selectedObjects = [...this.objects];
        }
        else {
            this.selectedObjects = [];
        }
    }
    onSelectedObjectsChanged() {
        this.selectAll = this.selectedObjects.length === this.objects.length;
    }
    onModelChanged() {
        this.$emit('change', {
            automationProcessSettings: this.automationProcess,
            objectsSettings: this.objects.map(object => ({
                objectName: object,
                disableAutomationProcess: this.selectedObjects.includes(object),
            })),
        });
    }
};
__decorate([
    Model('change')
], AutomationProcesses.prototype, "model", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], AutomationProcesses.prototype, "objects", void 0);
__decorate([
    Watch('selectedObjects')
], AutomationProcesses.prototype, "onSelectedObjectsChanged", null);
__decorate([
    Watch('selectedObjects', { deep: true, immediate: true }),
    Watch('automationProcess', { deep: true })
], AutomationProcesses.prototype, "onModelChanged", null);
AutomationProcesses = __decorate([
    Component({
        components: {
            ObjectsPicker,
        },
    })
], AutomationProcesses);
export default AutomationProcesses;
